@import '../../../assets/scss/variables.scss';
@import '../../../assets/scss/functions.scss';
@import '../../../assets/scss/mixins/flexbox.scss';
@import '../../../assets/scss/mixins/responsive.scss';

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: size-rem(96px) 5% size-rem(180px);
  flex-direction: column;
  position: relative;
  overflow: hidden;

  @include vp--740 {
    padding: size-rem(60px, true) 5%;
  }
}

.title {
  font-family: $main-font-family;
  font-size: size-rem(85px);
  font-weight: 350;
  line-height: 110%;
  letter-spacing: -0.02em;
  text-align: center;
  width: size-rem(350px);

  @include vp--740 {
    font-family: $main-font-family;
    font-size: size-rem(40px, true);
    font-weight: 350;
    line-height: size-rem(36px, true);
    letter-spacing: -0.02em;
    width: 100%;
    text-align: center;
  }
}

.svgLineBox {
  position: absolute;
  top: 0;
  left: 0;
  width: size-rem(1924px);
  height: size-rem(1786px);

  & svg {
    width: 100%;
    height: 100%;
    & path {
      transform: translateX(-10%);
    }
  }
}

.list {
  margin-top: size-rem(45px);
  display: grid;
  grid-template-columns: size-rem(280px) size-rem(269px) size-rem(302px) size-rem(278px);
  width: 100%;
  position: relative;
  z-index: 100;
  gap: size-rem(28px);
  left: 10%;

  & li {
    font-family: $main-font-family;
    font-size: size-rem(18px);
    font-weight: 350;
    line-height: size-rem(25.2px);
    text-align: left;
    color: $clr-black;
    box-sizing: border-box;
  }

  @include vp--740 {
    grid-template-columns: 1fr;
    left: 0;
    margin-top: size-rem(16px, true);
    list-style-position: inside;
    gap: 0;

    & li {
      font-size: size-rem(20px, true);
      line-height: size-rem(26px, true);
      padding: size-rem(24px, true) 0;
      border-bottom: 1px solid $clr-grey;
      padding-left: 5%;
      text-indent: -20px;

      &:last-child {
        border: none;
        padding-bottom: 0;
      }
    }
  }
}

.button {
  padding: size-rem(26px) size-rem(30px);
  width: fit-content;
  z-index: 10;
  background-color: $clr-white;
  margin-top: size-rem(61px);

  & div {
    font-family: $main-font-family;
    font-size: size-rem(16px);
    font-weight: 350;
    line-height: size-rem(19.2px);
    text-align: left;
    color: $clr-black;
  }

  @include vp--740 {
    margin-top: size-rem(40px, true);
    padding: size-rem(19px, true) size-rem(20px, true) size-rem(16px, true);
    height: fit-content;
    & div {
      font-size: size-rem(16px, true);
      line-height: size-rem(19.2px, true);
    }
  }
}

.item_1 {
  width: size-rem(178px);
}
.item_2 {
  width: size-rem(220px);
}
.item_3 {
  width: size-rem(270px);
}
.item_4 {
  width: size-rem(278px);
}
.item_5 {
  width: size-rem(250px);
}
.item_6 {
  width: size-rem(269px);
}
.item_7 {
  width: size-rem(240px);
}
.item_8 {
  width: size-rem(269px);
}

.item {
  @include vp--740 {
    width: 100%;
  }
}

.imageBox {
  position: relative;
  height: size-rem(520px);
  margin-top: size-rem(89px);

  @include vp--740 {
    height: size-rem(220px, true);
  }
}

.image {
  width: 100%;
  height: 100%;
  & img {
    height: 100%;
  }
}
