@import '../../assets/scss/variables.scss';
@import '../../assets/scss/functions.scss';
@import '../../assets/scss/mixins/flexbox.scss';
@import '../../assets/scss/mixins/responsive.scss';

.button {
  border: 1px solid $clr-grey;
  background-color: transparent;
  border-radius: size-rem(50px);
  padding: size-rem(16px) size-rem(28px);
  cursor: pointer;
  transition: all 0.5s ease;
  display: flex;
  align-items: center;
  text-transform: uppercase;

  @include vp--740 {
    padding: size-rem(16px, true) size-rem(28px, true);
    border-radius: size-rem(50px, true);
  }

  &:hover {
    background-color: $clr-black;
    transition: all 0.5s ease;
    border: 1px solid $clr-black;
    path {
      fill: $clr-white;
      transition: all 0.5s ease;
      @media (pointer: coarse) {
        fill: $clr-black;
      }
    }
    span {
      color: $clr-white;
      transition: all 0.5s ease;

      @media (pointer: coarse) {
        color: $clr-black;
      }
    }
    @media (pointer: coarse) {
      border: 1px solid $clr-grey;
      background-color: transparent;
    }
  }

  &:active {
    background-color: $clr-darker-grey;
    transition: all 0.5s ease;
    border: 1px solid $clr-black;
    path {
      fill: $clr-white;
      transition: all 0.5s ease;
    }
    span {
      color: $clr-white;
      transition: all 0.5s ease;
    }
  }
}

.text {
  font-family: $main-font-family;
  font-size: size-rem(16px);
  font-weight: 350;
  line-height: size-rem(19.2px);
  color: $clr-black;
  user-select: none;
  @include vp--740 {
    font-size: size-rem(16px, true);
    line-height: 110%;
  }
}

.icon {
  width: 100%;
  height: 100%;
}
